<template>
<p>login out</p>
</template>

<script>
import { inject } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

export default{

  name: 'AuthLogout',
  setup() {
    // get the global vuex store objects
    const store = useStore();
    const router = useRouter()

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');



    // when the vue module is created, get the token value, using the try catch
    try{

            let account_type = store.getters['auth/getAuthCredentials'].accountType
            // use axios to get the data
            axios.get(coreUrl.backendUrl+`logout/${account_type}`,{withCredentials: true}).then((response) =>{

                // If successful, get the resppnse data
                const responseData = response.data.data;

                console.log(responseData)

                const authToken = {
                    session:null,
                    token:null,
                    expiry:null
                };
                // update the vuex authentication data
                const authAuthentication = {
                    isAuthenticated : false,
                    isAccountCreated : false,
                    isProfileCreated : false
                }

                // update the vuex credentials data
                const authCredentials = {
                    accountName: null,
                    accountType :  "organization",
                    accountId : null,
                    accountHostId : null
                }


                // update the vuex permissions data
                const authPermissions = []

                // execute and update state for  token,authentication,credentials & permissions
                store.dispatch("auth/executeSetAuthToken",authToken );
                store.dispatch("auth/executeSetAuthAuthentication",authAuthentication);
                store.dispatch("auth/executeSetAuthCredentials",authCredentials);
                store.dispatch("auth/executeSetAuthPermissions",authPermissions);


                router.push('/login')

            })
     }catch(error){
            // if an error occours, do this
            console.log(error);
     }





  }
}
</script>
